<template>
  <Head>
    <title>Ремонт холодильников в Туле недорого. Выезд мастера на дом бесплатно.</title>
    <link rel="icon" href="/favicon.ico">
    <link rel="icon" href="/favicon.png">
    <meta name="description" content="Услуги мастера по ремонту холодильников в городе Тула. Профессиональный ремонт холодильников Indesit, Nordfrost, Beko, LG, Weissgauff, Haier, Comfee, Hotpoint-Ariston и другие марки." />
  </Head>
  <PageCover id="main" ref="main"/>
  <InfoCard id="info" ref="info"/>
  <WorkProcedure id="work" ref="work"/>
  <BrandsFridge id="brands" ref="brands"/>
  <PriceList id="price" ref="price"/>
  <BreakdownsFridge id="breakdowns" ref="breakdowns" @sendData="getData"/>
  <ReviewsSection id="reviews" ref="reviews"/>
  <ApplicationBlock id="applicationForm" ref="applicationForm" :cardData="cardData"/>
  <MapBlock id="map" ref="map"/>
</template>

<script>
import PageCover from "@/components/PageCover.vue";
import InfoCard from "@/components/InfoCard.vue";
import WorkProcedure from "@/components/WorkProcedure.vue";
import BrandsFridge from "@/components/BrandsFridge.vue";
import PriceList from "@/components/PriceList.vue";
import BreakdownsFridge from "@/components/BreakdownsFridge.vue";
import ReviewsSection from "@/components/ReviewsSection.vue";
import ApplicationBlock from "@/components/ApplicationBlock.vue";
import MapBlock from "@/components/MapBlock.vue";
import {Head} from "@vueuse/head";

export default {
  components: {
    Head,
    MapBlock,
    ApplicationBlock,
    ReviewsSection,
    BreakdownsFridge,
    PriceList,
    BrandsFridge,
    WorkProcedure,
    InfoCard,
    PageCover
  },
  data() {
    return {
      cardData: "",
    }
  },
  methods: {
    getData(d) {
      this.cardData = d;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
