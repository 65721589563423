<template>
  <navigation-bar/>
  <router-view/>
  <v-footer/>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import VFooter from "@/components/v-Footer.vue";

export default {
  components: {NavigationBar, VFooter },
}
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Raleway, sans-serif;
  }

  #app {
    overflow-x: hidden;
  }
</style>