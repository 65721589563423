<template>
  <section class="prices">
    <div class="content">
      <h2 class="title">Цены на ремонт</h2>
      <div class="price__list">
        <div class="price__item"
             v-for="price in prices"
             :key="price"
        >
          <h3 class="name"><span>{{price.name}}</span></h3>
          <span class="line"></span>
          <h3 class="price">{{price.price}}</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import prices from "@/json/prices.json";
export default {
  data() {
    return {
      prices: prices
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base/adaptive";

.prices {
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 900px;
    margin-bottom: 150px;

    .title {
      text-align: center;
    }

    .price__list {
      padding: 60px;
      background: var(--gradient);
      border-radius: 10px;
      box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.10);
      display: flex;
      flex-direction: column;
      gap: 30px;

      .price__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        .name {
          color: var(--green-color);
          text-align: left;
        }

        .name span {
          vertical-align: middle;
          display: flex;
          white-space: nowrap;
          width: 100%;
        }
        .line {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 100%;
          height: 1px;
          background-color: var(--gray-lines-color);
        }

        .price {
          white-space: nowrap;
          color: var(--black-color);
          font-variant-numeric: lining-nums proportional-nums;
        }
      }
    }
  }
}



@media only screen and (max-width: $bp-pc){
.prices {


  .content {
    max-width: 670px;
    gap: 50px;
    margin-bottom: 100px;

    .title {

    }

    .price__list {
      padding: 40px;


      .price__item {


        .line {

        }
        h3 {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
        }
      }
    }
  }
}
}
@media only screen and (max-width: $bp-tablet){
  .prices {


    .content {
      max-width: unset;
      gap: 30px;

      margin-bottom: 80px;

      .title {
        padding-top: 30px;
      }

      .price__list {
        padding: 30px 20px;
        gap: 20px;

        box-shadow: unset;
        .price__item {
          gap: 10px;

          .name {
            span {
              white-space: break-spaces;

            }
          }
          .line {

          }
          h3 {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
          }
        }

      }
    }
  }
}
@media only screen and (max-width: $bp-phone) {
  .prices {

    .content {

      .price__list {

        .price__item {

          .name {

          }
          .line {

          }
        }
      }
    }
  }
}

</style>