<template>
  <section class="cover">
    <div class="content__cover">
      <div class="cover__title">
        <h1 class="title">
          Ремонт холодильников в Туле. Починка морозильных камер и оборудования.
        </h1>
        <h2 class="description">
          Мы ремонтируем марки Beko, Montpellier Indesit, Panasonic, Electrolux.
        </h2>
      </div>
      <div class="cover__phone">
        <a href="tel:+79193441466" class="phone">+7 (919) 344 14-66</a>
        <social-btns />
      </div>
      <div class="tags">
        <div class="tag">Быстро</div>
        <div class="tag">Качественно</div>
        <div class="tag">С гарантией</div>
      </div>
      <router-link :to="{path: '/', hash: '#info', top: 60}" class="arrow-btn">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="material-symbols:arrow-left-alt-rounded">
            <path id="Vector" d="M13 16.15L15.85 13.3C16.05 13.1 16.2834 13.004 16.55 13.012C16.8167 13.02 17.05 13.116 17.25 13.3C17.45 13.5 17.5544 13.7373 17.563 14.012C17.5717 14.2867 17.4757 14.5243 17.275 14.725L12.7 19.3C12.5 19.5 12.2667 19.6 12 19.6C11.7334 19.6 11.5 19.5 11.3 19.3L6.72504 14.725C6.52504 14.525 6.42937 14.2873 6.43804 14.012C6.44671 13.7367 6.55071 13.4993 6.75004 13.3C6.95004 13.1167 7.18337 13.0207 7.45004 13.012C7.71671 13.0033 7.95004 13.0993 8.15004 13.3L11 16.15L11 5.00001C11 4.71667 11.096 4.47901 11.288 4.28701C11.48 4.09501 11.7174 3.99934 12 4.00001C12.2834 4.00001 12.521 4.09601 12.713 4.28801C12.905 4.48001 13.0007 4.71734 13 5.00001L13 16.15Z" fill="var(--white-color)"/>
          </g>
        </svg>
      </router-link>
    </div>
    <img class="cover__img" src="@/assets/images/fridge.webp" alt="fridge">
  </section>
</template>

<script>
import SocialBtns from "@/components/UI/SocialBtns.vue";

export default {
  components: {SocialBtns}

}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base/adaptive.scss";

  .cover {
    margin: 0 auto;
    background: var(--green-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    .content__cover {
      max-height: 557px;
      width: 1400px;
      min-width: 1140px;
      display: flex;
      flex-direction: column;
      padding: 80px 20px 40px 20px;

      .cover__title {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .title {
          color: var(--white-color);
          font-size: 44px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-right: 40%;

        }
        .description {
          padding-right: 60%;
          color: var(--white-color);
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .cover__phone {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;

        .phone {
          color: var(--white-color);
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .phone-btn__block {
          display: flex;
          flex-direction: row;
          gap: 10px;
          .phone-btn {
            background: var(--white-color);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: none;
          }
        }
      }
      .tags {
        margin-top: 26px;
        display: flex;
        flex-direction: row;
        gap: 20px;

        .tag {
          padding: 20px 30px;
          border-radius: 10px;
          background: var(--white-color);

          color: var(--green-color);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .arrow-btn {
        display: none;
        width: 44px;
        height: 44px;
        background: none;
        border-radius: 50%;
        border: 3px solid var(--white-color);
      }
    }
    .cover__img {
      position: absolute;
      right: 26.7%;
      bottom: 0;
    }
  }

@media only screen and (max-width: $bp-big-pc) {
  .cover {
    .content__cover {
      width: 1320px;
      min-width: 1100px;
    }
    .cover__img {
      position: absolute;
      right: 21.3%;
      bottom: 0;
    }
  }

}
@media only screen and (max-width: $bp-pc) {
  .cover {
    .content__cover {
      width: 1140px;
      min-width: 960px;
      padding: 50px 20px 50px 20px;

      .cover__title {
        gap: 20px;

        .title {
          font-size: 40px;
        }
        .description {
          font-size: 16px;
          padding-right: 59.3%;
        }
      }

      .cover__phone {
        gap: 20px;
        margin-top: 41px;

        .phone {
          font-size: 32px;
        }
        .phone-btn__block {
          .phone-btn {
            width: 40px;
            height: 40px;
            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .tags {
        margin-top: 38px;
        .tag {
          font-size: 16px;
          padding: 15px 20px;
        }
      }
    }
    .cover__img {
      position: absolute;
      right: 15%;
      bottom: 0;
      height: 95%;
    }
  }

}
@media only screen and (max-width: $bp-big-tablet) {
  .cover {
    .content__cover {
      width: 896px;
      min-width: 768px;
      z-index: 1;

      .cover__title {
        .description {
          padding-right: 50%;
        }
      }
    }
    .cover__img {
      position: absolute;
      right: 5%;
      bottom: 0;
      height: 95%;
      z-index: 0;
    }
  }

}

@media only screen and (max-width: $bp-tablet) {
  .cover {
    .content__cover {
      width: 670px;
      min-width: 339px;
      justify-content: center;
      align-items: center;
      padding: 44px 20px 30px 20px;

      .cover__title {
        justify-content: center;
        align-items: center;

        .title {
          font-size: 24px;
          text-align: center;
          padding-right: unset;
        }
        .description {
          font-size: 14px;
          padding-right: unset;
          text-align: center;
        }
      }

      .cover__phone {
        margin-top: 40px;
        flex-direction: column;
        gap: 10px;

        .phone {
          font-size: 24px;
        }
      }

      .tags {
        margin-top: 40px;
        .tag {
          font-size: 13px;
          padding: 10px;
          white-space: nowrap;
        }
      }

      .arrow-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
      }
    }
    .cover__img {
      display: none;
    }
  }

}
@media only screen and (max-width: $bp-phone) {
  .cover {
    .content__cover {

    }
  }

}
</style>