<template>
  <section class="map">
    <div class="content">
      <h2 class="title">Зона выезда</h2>
      <yandex-map class="ymap" :coords="mapCoords" :zoom="mapZoom">
        <ymap-marker
            marker-type="Polygon"
            :marker-id="'index'"
            :coords="coords"
            :marker-fill="{ color: '#188C48FF', opacity: 0.2 }"
            :marker-stroke="{ color: '#188C48FF', width: 1 }"
        />
      </yandex-map>
    </div>
  </section>
</template>



<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps';

export default {
  components: { yandexMap, ymapMarker },
  data() {
    return {
      mapCoords: [54.193122, 37.617347],
      mapZoom: 12.35,
      coords: [this.createCirclePoints([54.193122, 37.617347], 10000)]
    };
  },
  methods: {
    createCirclePoints(center, radius) {
      const points = [];
      const N = 36;
      const earthRadius = 8800e3;

      const lat = center[0] * Math.PI / 180;
      const lon = center[1] * Math.PI / 180;

      for (let i = 0; i < N; i++) {
        const angle = 2 * Math.PI * i / N;
        const circleLat = Math.asin(Math.sin(lat) * Math.cos(radius / earthRadius) +
            Math.cos(lat) * Math.sin(radius / earthRadius) * Math.cos(angle));
        const circleLon = lon + Math.atan2(Math.sin(angle) * Math.sin(radius / earthRadius) * Math.cos(lat),
            Math.cos(radius / earthRadius) - Math.sin(lat) * Math.sin(circleLat));

        points.push([
          circleLat * 180 / Math.PI,
          circleLon * 180 / Math.PI
        ]);
      }
      return points;
    }
  }
}
</script>



<style lang="scss">
@import "@/assets/styles/base/adaptive";

  .map {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      max-width: 1360px;
      width: 100%;
      margin-bottom: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;

      .title {
        text-align: center;
      }

      .ymap {
        height: 824px;
        width: 100%;
        box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.10);

        .ymaps-2-1-79-controls__toolbar_left,
        .ymaps-2-1-79-controls__toolbar,
        .ymaps-2-1-79-map-copyrights-promo {
          display: none;
        }
      }
    }
  }

@media only screen and (max-width: $bp-big-pc){
  .map {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {

      .ymap {
        max-width: 1320px;
        height: 824px;
      }
    }
  }
}
@media only screen and (max-width: $bp-pc){
  .map {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      .ymap {
        max-width: 670px;
        height: 566px;
      }
    }
  }
}
@media only screen and (max-width: $bp-tablet){
  .map {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      gap: 50px;
      .ymap {
        min-width: 300px;
        height: 250px;
      }
    }
  }
}
@media only screen and (max-width: $bp-phone){
  .map {

    .content {
      margin-bottom: 80px;
      gap: 30px;
    }
  }
}
</style>