<template>
  <Head>
    <title>404: Страница не найдена</title>
    <link rel="icon" href="/favicon.ico">
    <link rel="icon" href="/favicon.png">
    <meta name="description" content="Ошибка 404." />
  </Head>
  <section class="error">
    <div class="content">
      <p class="title">Ошибка 404</p>
      <p class="description">Кажется, такой страницы не существует</p>
      <router-link class="back-btn" to="/">Вернуться на главную страницу</router-link>
    </div>
  </section>
</template>

<script>
import {Head} from "@vueuse/head";

export default {
  components: {Head}

}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base/adaptive";

  .error {
    margin: 0 auto;

    .content {
      height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        color: var(--green-color);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;
      }

      .description {
        color: var(--black-color);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 60px;
      }

      .back-btn {
        border-radius: 100px;
        border: 2px solid var(--green-color);
        padding: 20px 30px;
        justify-content: center;
        align-items: center;

        color: var(--green-color);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &:hover {
          border: 2px solid var(--white-color);
          background: var(--green-color);
          color: var(--white-color);
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
@media only screen and (max-width: $bp-pc) {
  .error {
    .content {
      height: calc(100vh - 90px);
    }
  }
}
@media only screen and (max-width: $bp-big-tablet) {
  .error {
    .content {
      height: calc(100vh - 80px);
    }
  }
}
@media only screen and (max-width: $bp-tablet) {
  .error {

    .content {
      height: calc(100vh - 90px);

      .title {
        font-size: 36px;
        margin-bottom: 10px;
      }

      .description {
        font-size: 16px;
        margin-bottom: 30px;
      }

      .back-btn {
        padding: 15px 30px;
        font-size: 12px;
      }
    }
  }
}
</style>