<template>
  <section class="reviews">
    <div class="content">
      <h2 class="title">Отзывы клиентов</h2>
      <div class="slider">
        <div class="nav-btns">
          <img class="prev" @click="swiperSwipeTo('prev')" src="@/assets/images/icons/arrow-nav_icon.svg" alt="arrow-nav-prev">
          <img class="next" @click="swiperSwipeTo('next')" src="@/assets/images/icons/arrow-nav_icon.svg" alt="arrow-nav-next">
        </div>
        <swiper class="swiper" id="reviews-swiper"
                :slides-per-view="1"
                :slides-per-group="1"
                :modules="modules"
                :space-between="20"
                @slideChange="updateNavigationButtons"
                :pagination="{
                  clickable: true,
                  el: '.swiper-pagination'
                }"
                :breakpoints="{
                  1441: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    allowTouchMove: false
                  }
                }"
        >
          <swiper-slide
              v-for="review in reviews"
              :key="review"
              class="review__item"
          >
            <div class="slide_wrapper">
              <div class="user-info">
                <span class="user-icon">
                  <img class="icon" src="@/assets/images/icons/user_icon.svg" alt="user-icon">
                </span>
                <p class="name">{{review.name}}</p>
              </div>
              <span class="review">{{review.review}}</span>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/scss'
import 'swiper/scss/pagination'
import reviews from "@/json/reviews.json"
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination]
    }
  },
  data() {
    return {
      reviews: reviews,
    }
  },
  methods: {
    swiperSwipeTo(direction) {
      const swiperEl = document.getElementById('reviews-swiper').swiper;
      if (direction === 'prev') {
        swiperEl.slidePrev();
      } else {
        swiperEl.slideNext();
      }
    },
    updateNavigationButtons() {
      const swiper = document.getElementById('reviews-swiper').swiper;
      const next = document.querySelector('.next');
      const prev = document.querySelector('.prev');

      if (swiper.isBeginning) {
        prev.classList.add('disabled');
      } else {
        prev.classList.remove('disabled');
      }

      if (swiper.isEnd) {
        next.classList.add('disabled');
      } else {
        next.classList.remove('disabled');
      }
    }
  },
  mounted() {
    this.updateNavigationButtons();
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base/adaptive';

.reviews {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    width: 1360px;
    padding: 0 115px;
    margin-bottom: 150px;

    .slider {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      position: relative;

      .nav-btns {
        position: absolute;
        top: 35.3%;

        img {
          background: var(--green-color);
          border-radius: 50%;
          padding: 10px;
          cursor: pointer;
        }
        .prev {
          position: absolute;
          transform: rotate(180deg);
          left: -116px;
        }
        .next {
          position: absolute;
          right: -1245px;
        }
        .disabled {
          background: var(--gray-lines-color);
        }
      }

      .swiper {
        max-width: 100%;

        .swiper-slide {
          display: flex;
          width: auto;

          .slide_wrapper {
            display: flex;
            flex-direction: column;
            width: 555px;
            height: 292px;
            gap: 30px;
            padding: 30px 30px 40px 30px;
            border-radius: 10px;
            border: 2px solid var(--gray-lines-color);

            .user-info {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 20px;

              .user-icon {
                display: flex;
                width: 45px;
                height: 45px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: var(--gray-lines-color);
              }
              .name {
                color: var(--green-color);
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
            }
            .review {
              color: var(--black-color);
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 130%;
            }
          }

          .swiper-button-prev::after,
          .swiper-button-next::after {
            font-family: unset;
            content: ""
          }
        }
      }

      .swiper-pagination {
        position: relative;
        bottom: -40px;
        width: 100%;
        text-align: center;

        .swiper-pagination-bullet {
          width: 30px;
          height: 30px;
          background: var(--gray-lines-color);
          opacity: 1;
          border-radius: 50%;
        }

        .swiper-pagination-bullet-active {
          background: var(--green-color);
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-big-pc) {
  .reviews {
    .content {
      width: 1320px;
      .slider {
        .nav-btns {
          .next {
            right: -1205px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $bp-pc) {
  .reviews {
    .content {
      width: 670px;
      gap: 40px;
      margin-bottom: 100px;

      .slider {
        .nav-btns {
          .next {
            right: -555px;
          }
        }
        .swiper {
          .swiper-wrapper {
            .swiper-slide {
              .slide_wrapper {
                height: unset;
                gap: 20px;

                .user-info {
                  .user-icon {
                    width: 40px;
                    height: 40px;
                  }
                  .icon {

                  }
                  .name {
                    font-size: 20px;
                  }
                }
                .review {
                  font-size: 14px;
                }
              }
            }
          }

        }
        .swiper-pagination {
          bottom: -20px;
        }
      }
    }
  }
}
@media only screen and (max-width: $bp-tablet) {
  .reviews {
    .content {
      max-width: 100%;
      gap: 30px;
      margin-bottom: 80px;
      padding: 0;

      .slider {

        .nav-btns {
          display: none;
        }
        .swiper {
          max-width: 80%;
          .swiper-wrapper {
            .swiper-slide {
              .slide_wrapper {
                height: unset;
                gap: 10px;
                padding: 30px;

                .user-info {
                  .user-icon {
                    width: 30px;
                    height: 30px;
                  }
                  .icon {
                    width: 16px;
                    height: 16px;
                  }
                  .name {
                    font-size: 16px;
                  }
                }
                .review {
                  font-size: 12px;
                }
              }
            }
          }

        }
        .swiper-pagination {
          bottom: -20px;
          .swiper-pagination-bullet {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

</style>