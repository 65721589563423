<template>
  <section class="procedure">
    <div class="content">
      <h2 class="title">Порядок работы</h2>
      <div class="procedure__list">
        <div class="procedure__item">
          <span class="icon">
            <img src="@/assets/images/icons/document_icon.svg" alt="document_icon">
          </span>
          <h3 class="description">
            Получаем от вас заявку на ремонт холодильника
          </h3>
        </div>
        <div class="procedure__item">
          <span class="icon">
            <img src="@/assets/images/icons/calendar_icon.svg" alt="calendar_icon">
          </span>
          <h3 class="description">
            Выбираем с вами удобную дату и время приезда
          </h3>
        </div>
        <div class="procedure__item">
          <span class="icon">
            <img src="@/assets/images/icons/tools_icon.svg" alt="tools_icon">
          </span>
          <h3 class="description">
            Мастер проводит диагностику и называет цену на ремонт
          </h3>
        </div>
        <div class="procedure__item">
          <span class="icon">
            <img src="@/assets/images/icons/check_icon.svg" alt="check_icon">
          </span>
          <h3 class="description">
            Ваш холодильник снова исправен!
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base/adaptive.scss";

.procedure {
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .content {
    width: 1360px;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    gap: 60px;

    .title {
      text-align: center;
    }

    .procedure__list {
      display: flex;
      flex-direction: row;
      gap: 60px;
      justify-content: center;

      .procedure__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        .icon {
          width: 125px;
          height: 125px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          background: var(--green-color);
        }
        .description {
          text-align: center;
          width: 285px;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-big-pc) {
  .procedure {

    .content {
      width: 1320px;
    }
  }
}
@media only screen and (max-width: $bp-pc) {
  .procedure {

    .content {
      max-width: 1140px;
      width: auto;
      gap: 40px;
      margin-bottom: 100px;

      .procedure__list {
        gap: 30px;

        .procedure__item {
          gap: 19px;

          .icon {
            width: 60px;
            height: 60px;
            border-radius: 50.758px;

            img {
              width: 30px;
              height: 30px;
            }
          }
          .description {
            width: 145px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $bp-tablet) {
  .procedure {
    display: none;
    visibility: hidden;
  }
}
</style>