<template>
  <div class="phone-btn__block">
    <a href="tel:+79193441466" class="phone-btn">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_23_499)">
          <path d="M3.66667 0C2.69421 0 1.76158 0.386308 1.07394 1.07394C0.386308 1.76158 0 2.69421 0 3.66667L0 6.6C0 15.1052 6.8948 22 15.4 22H18.3333C19.3058 22 20.2384 21.6137 20.9261 20.9261C21.6137 20.2384 22 19.3058 22 18.3333V16.3064C22 15.8976 21.8861 15.4969 21.6711 15.1493C21.456 14.8016 21.1484 14.5207 20.7827 14.3381L17.2407 12.5664C16.9587 12.4254 16.6497 12.3467 16.3346 12.3356C16.0195 12.3244 15.7057 12.3811 15.4145 12.5019C15.1232 12.6226 14.8613 12.8045 14.6465 13.0353C14.4317 13.2661 14.269 13.5403 14.1695 13.8395L13.7324 15.1492C13.652 15.3896 13.4866 15.5923 13.2674 15.7195C13.0481 15.8467 12.79 15.8895 12.5415 15.84C10.9669 15.525 9.52071 14.7512 8.38513 13.6158C7.24954 12.4805 6.47532 11.0345 6.16 9.46C6.11049 9.21143 6.15333 8.95333 6.2805 8.73408C6.40768 8.51484 6.61044 8.3495 6.8508 8.26907L8.47733 7.7264C9.00298 7.55087 9.44301 7.18343 9.70948 6.69753C9.97595 6.21163 10.0492 5.64306 9.91467 5.10547L9.0552 1.6676C8.9365 1.19169 8.66217 0.7691 8.27579 0.46696C7.88942 0.16482 7.41315 0.000462542 6.92267 0L3.66667 0Z" fill="var(--green-color)"/>
        </g>
        <defs>
          <clipPath id="clip0_23_499">
            <rect width="22" height="22" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </a>
    <a href="#" class="phone-btn">
      <img src="@/assets/images/icons/telegram_icon.svg" alt="telegram-icon">
    </a>
    <a href="#" class="phone-btn">
      <img src="@/assets/images/icons/whatsapp_icon.svg" alt="whatsapp-icon">
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.phone-btn__block {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .phone-btn {
    background: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
  }
}
</style>