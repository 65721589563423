<template>
  <section class="application">
    <div class="content">
      <img class="bg-image" src="@/assets/images/master-tools.webp" alt="master-tools">
      <h2 class="title">
        Оставьте заявку и мы перезвоним вам в ближайшее время!
      </h2>
      <form class="form" ref="form" @submit.prevent="sendEmail">
        <input
            class="input name"
            type="text"
            v-model="formData.fullName"
            placeholder="Ваше имя"
            name="fullName"
        >
        <input
            class="input phone"
            type="text"
            ref="phone"
            placeholder="Ваш номер телефона"
            name="phoneNumber"
            @input="($ev) => {this.$refs.phone.value = phoneInput($ev); formData.phoneNumber=phoneInput($ev)}"
        >
        <textarea
            class="input description-area"
            v-model="formData.description"
            placeholder="Описание поломки (необязательно)"
            name="description">
        </textarea>
        <button class="send-btn" type="submit" value="Send">Отправить</button>
        <span class="error-message" v-show="formFull">{{ formFull }}</span>
      </form>
      <modal-window
          :is-visible="showModal"
          @update:isVisible="showModal = $event"
          @update:formFull="formFull = $event"
      >
        <p class="modal-text">Ваша заявка успешно отправлена!</p>
      </modal-window>
    </div>
  </section>
</template>

<script>
import emailjs from '@emailjs/browser';
import {phoneInput} from "/src/utils/mask.js"
import ModalWindow from "@/components/ModalWindow.vue";
export default {
  components: {ModalWindow},
  props: ['cardData'],
  data() {
    return {
      formData: {
        fullName: "",
        phoneNumber: "",
        description: "",
      },
      showModal: false,
      formFull: ""
    }
  },
  watch: {
    cardData(value) {
      if (value !== '') {
        this.formData.description = this.cardData
      }
    }
  },
  methods: {
    phoneInput,
    sendEmail() {
      this.formFull = {};

      if (!this.formData.fullName || !this.formData.phoneNumber) {
        this.formFull = 'Заполнены не все данные!';
      }

      if (this.formData.fullName && this.formData.phoneNumber) {
        const err = document.querySelector(".error-message")
        err.style.color = "white";
        this.formFull = 'Заявка отправляется...';
      }

      if (this.formFull === "Заявка отправляется...") {
        emailjs.send("service_gmsxmma", "template_crm0a2h", this.formData, "OUUJG_tZ00VsIRCl2")
            .then((result) => {
              this.showModal = true;
              this.formData.fullName = ""
              this.formData.phoneNumber = ""
              this.formData.description= ""
              this.$refs.phone.value = ""
              console.log('SUCCESS!', result.text);
            }, (error) => {
              console.log('FAILED...', error.text);
            });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/base/adaptive";

  .application {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      margin-bottom: 150px;
      position: relative;
      padding: 100px 40px;
      width: 1360px;
      background-color: var(--green-color);
      border-radius: 10px;
      box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.10);
      display: flex;
      flex-direction: column;
      align-items: center;

      .bg-image {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 1;
        z-index: 9;
        mix-blend-mode: hard-light;
        border-bottom-left-radius: 10px;
      }
      .title {
        text-align: center;
        color: var(--white-color);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 40px;
        max-width: 680px;
      }
      .form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        z-index: 10;
        width: 500px;

        .error-message {
          background-color: rgba(255, 255, 255, 0.3);
          border-radius: 10px;
          backdrop-filter: blur(30px);
          color: red;
          font-size: 16px;
          padding: 5px 10px;
          margin-top: 5px;
          display: flex;
          justify-content: center;
        }

        .input {
          border: none;
          border-radius: 5px;
          background: var(--white-color);
          display: flex;
          width: 100%;
          padding: 20px;
          align-items: center;
          gap: 10px;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: var(--black-color);
          font-variant-numeric: lining-nums proportional-nums;
          &::placeholder {
            color: var(--gray-text-color);
            font-variant-numeric: lining-nums proportional-nums;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
        .description-area {
          min-height: 163px;
          margin: 10px 0;
          resize: none;
        }
        .send-btn {
          width: 100%;
          border-radius: 5px;
          background-color: var(--green-color);
          border: 2px solid var(--white-color);
          display: flex;
          padding: 20px 30px;
          justify-content: center;
          align-items: center;
          color: var(--white-color);
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          &:hover {
            border: 2px solid var(--green-color);
            background: var(--white-color);
            color: var(--green-color);
            transition: 0.2s ease-in-out;
          }
        }
      }
    }
  }

@media only screen and (max-width: $bp-big-pc) {
  .application {
    .content {
      width: 1320px;
    }
  }
}
@media only screen and (max-width: $bp-pc) {
  .application {
    .content {
      width: 670px;
      padding: 40px;

      .title {
        font-size: 32px;
        max-width: 590px;
      }

      .form {
        width: 438px;
        .send-btn {
          background: rgba(24, 140, 72, 0.30);
          backdrop-filter: blur(8px);

          &:hover {
            border: 2px solid var(--white-color);
            background: rgba(255, 255, 255, 0.3);
            color: var(--white-color);
            transition: 0.2s ease-in-out;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $bp-tablet) {
  .application {
    .content {
      width: 100%;
      padding: 40px 20px;
      border-radius: unset;
      margin-bottom: 100px;

      .title {
        font-size: 32px;
        max-width: 590px;
      }

      .bg-image {
        width: 252px;
        border-bottom-left-radius: unset;
      }
      .form {
        max-width: 438px;
        width: 80%;

        .error-message {
          font-size: 14px;
        }

        .send-btn {

        }
      }
    }
  }
}
@media only screen and (max-width: $bp-phone) {
  .application {
    .content {
      margin-bottom: 80px;
      .title {
        font-size: 20px;
      }
      .form {
        .input {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
        .send-btn {
          font-size: 16px;
          padding: 15px 30px;
        }
      }
    }
  }
}
</style>