import { createRouter, createWebHistory } from 'vue-router'

import HomeView from "@/components/view/HomeView.vue";

import HomePage from "@/pages/HomePage.vue";

import ApplicationBlock from "@/components/ApplicationBlock.vue";
import pageCover from "@/components/PageCover.vue";
import infoCard from "@/components/InfoCard.vue";
import workProcedure from "@/components/WorkProcedure.vue";
import brandsFridge from "@/components/BrandsFridge.vue";
import priceList from "@/components/PriceList.vue";
import BreakdownsFridge from "@/components/BreakdownsFridge.vue";
import reviewsSection from "@/components/ReviewsSection.vue";
import mapBlock from "@/components/MapBlock.vue";
import ErrorPage from "@/pages/ErrorPage.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    children: [
      {
        path: "",
        name: "HomePage",
        component: HomePage,
        children: [
          {
            path: '/main',
            name: 'Main',
            component: pageCover
          },
          {
            path: '/info',
            name: 'Info',
            component: infoCard
          },
          {
            path: '/work',
            name: 'Work',
            component: workProcedure
          },
          {
            path: '/brands',
            name: 'Brands',
            component: brandsFridge
          },
          {
            path: '/price',
            name: 'Price',
            component: priceList
          },
          {
            path: '/breakdowns',
            name: 'Breakdowns',
            component: BreakdownsFridge
          },
          {
            path: '/reviews',
            name: 'Reviews',
            component: reviewsSection
          },
          {
            path: '/application-form',
            name: 'Application-Form',
            component: ApplicationBlock
          },
          {
            path: '/map',
            name: 'Map',
            component: mapBlock
          },
        ]
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 250,
        behavior: "smooth"
      };
    }
    return window.scrollTo(0, 0);
  },
})

export default router
