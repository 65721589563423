<template>
  <section class="breakdown">
    <div class="content">
      <h2 class="breakdown__title">Частые поломки холодильников</h2>
      <div class="breakdown__list">
        <div class="breakdown__item"
             v-for="br in breakdowns"
             :key="br"
        >
          <h2 class="title">
            {{br.name}}
          </h2>
          <div class="info">
            <div class="container">
              <div class="signs">
                <h3 class="name">Признак: </h3>
                <ul class="list">
                  <li class="item" v-for="sign in br.signs" :key="sign">{{sign}}</li>
                </ul>
              </div>
              <div class="solution">
                <h3 class="name">Возможное решение: </h3>
                <ul class="list">
                  <li class="item">{{br.solution}}</li>
                </ul>
              </div>
            </div>
            <application-btn @click="sendData(br.send)"/>
          </div>
        </div>
      </div>
      <div class="slider">
        <swiper class="swiper"
                slides-per-view="auto"
                :space-between="10"
        >
          <swiper-slide
              v-for="br in breakdowns"
              :key="br"
              class="breakdown__item"
          >
            <div class="slide-wrapper">
              <h2 class="title">
                {{br.name}}
              </h2>
              <div class="info">
                <div class="container">
                  <div class="signs">
                    <h3 class="name">Признак: </h3>
                    <ul class="list">
                      <li class="item" v-for="sign in br.signs" :key="sign">{{sign}}</li>
                    </ul>
                  </div>
                  <div class="solution">
                    <h3 class="name">Возможное решение: </h3>
                    <ul class="list">
                      <li class="item">{{br.solution}}</li>
                    </ul>
                  </div>
                </div>
                <application-btn @click="sendData(br.send)"/>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import breakdowns from "@/json/breakdowns.json"
import ApplicationBtn from "@/components/UI/ApplicationBtn.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';

export default {
  components: {ApplicationBtn, Swiper, SwiperSlide},
  data() {
    return {
      breakdowns: breakdowns
    }
  },
  methods: {
    sendData(d) {
      this.$emit('sendData', d)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base/adaptive";

  .breakdown {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      margin-bottom: 150px;
      width: 100%;
      max-width: 1360px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;

      .breakdown__title {
        text-align: center;
      }

      .breakdown__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        .breakdown__item {
          width: 440px;
          height: 500px;
          padding: 40px;
          border-radius: 10px;
          border: 2px solid var(--gray-lines-color);
          background: var(--white-color);
          display: flex;
          flex-direction: column;
          gap: 30px;

          .title {
            color: var(--green-color);
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
          }

          .info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            height: 100%;
            justify-content: space-between;

            .container {
              display: flex;
              flex-direction: column;
              gap: 20px;
              align-items: start;

              .name {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
              .list {
                padding-top: 8px;
                padding-left: 25px;
              }
              .item {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;

              }
            }
            .application-btn {
              padding: 15px 30px;
              border: 2px solid var(--green-color);
              background: var(--white-color);
              color: var(--green-color);

              &:hover {
                border: 2px solid var(--white-color);
                background: var(--green-color);
                color: var(--white-color);
                transition: 0.2s ease-in-out;
              }
            }
          }
        }
      }
      .slider {
        display: none;
        max-width: 100%;


        .swiper {
          overflow: visible;
          max-width: 100%;

          .swiper-slide {
            width: auto;
            height: auto;

            .slide-wrapper {
              width: 220px;
              height: 309px;
              padding: 20px;
              border-radius: 10px;
              border: 2px solid var(--gray-lines-color);
              background: var(--white-color);
              display: flex;
              flex-direction: column;
              gap: 10px;

              .title {
                color: var(--green-color);
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
              }

              .info {
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 100%;
                justify-content: space-between;

                .container {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  align-items: start;

                  .name {
                    color: var(--black-color);

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                  }
                  .list {
                    padding-top: 3px;
                    padding-left: 25px;
                  }
                  .item {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 130%;

                  }
                }
                .application-btn {
                  padding: 10px 30px;
                  border: 2px solid var(--green-color);
                  background: var(--white-color);
                  color: var(--green-color);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  height: unset;

                  &:hover {
                    border: 2px solid var(--white-color);
                    background: var(--green-color);
                    color: var(--white-color);
                    transition: 0.2s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

@media only screen and (max-width: $bp-big-pc) {
    .breakdown {
      .content {
        max-width: 1320px;
        .breakdown__list {
          .breakdown__item {
            width: 425px;
          }
        }
      }
    }
  }
@media only screen and (max-width: $bp-pc) {
  .breakdown {
    overflow-x: hidden;
    .content {
      padding: 0 20px;
      max-width: 1320px;
      margin-bottom: 100px;
      gap: 50px;

      .breakdown__list {
        justify-content: center;
        display: none;
        .breakdown__item {
          width: 325px;

          .title {
            color: var(--green-color);
            font-size: 23px;
          }

          .info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            height: 100%;
            justify-content: space-between;

            .container {

              .name {
                font-size: 16px;
              }

              .list {
                padding-left: 25px;
              }

              .item {
                font-size: 14px;
              }
            }
          }
        }
      }
      .slider {
        display: inline-block;
      }
    }
  }
}
@media only screen and (max-width: $bp-pc) {
  .breakdown {

    .content {
      margin-bottom: 80px;
    }
  }
}
@media only screen and (max-width: $bp-tablet) {
  .breakdown {

    .content {
      margin-bottom: 80px;
      gap: 30px;
    }
  }
}
</style>