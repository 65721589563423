<template>
  <section class="info-card">
    <div class="content">
      <img class="image" src="@/assets/images/master.webp" alt="master">
      <h2 class="title">Профессиональный ремонт бытовой техники в вашем распоряжении!</h2>
      <p class="main-info">
        Успешно обслуживаем жителей Тулы и области более 8 лет.
        Наш опыт и профессиональные навыки позволяют нам решать самые разнообразные проблемы с техникой различных марок.
        Мы гордимся тем, что каждый наш клиент получает надежный и быстрый ремонт без лишних затрат.
        Зачастую наши заказы направлены на переделку работ за другими недобросовестными мастерами, для нас главное чтобы клиент всегда оставался довольным после нашей работы.
      </p>
      <div class="phone-info">
        <a href="tel:+79193441466" class="phone">+7 (919) 344 14-66</a>
        <p>Прием заявок на ремонт с 9:00 до 20:00</p>
      </div>
      <social-btns />
    </div>
  </section>
</template>

<script>
import SocialBtns from "@/components/UI/SocialBtns.vue";

export default {
  components: {SocialBtns}

}
</script>

<style lang="scss" >
@import "@/assets/styles/base/adaptive.scss";

  .info-card {
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .content {
      width: 1360px;
      margin: 200px 0 150px 0;
      padding: 60px;
      background: var(--gradient);
      border-radius: 10px;
      box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.10);

      display: grid;
      grid-template-areas:
          'image row'
          'image title'
          'image main-info'
          'image phone-info'
          'image socials'
          'image row2';
      grid-template-rows: 1fr auto auto auto auto 1fr;
      grid-gap: 0 80px;
      align-content: center;

      .row {
        grid-area: row;
      }
      .row2 {
        grid-area: row2;
      }

      .title {
        grid-area: title;
        align-self: center;
        color: var(--green-color);
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        padding-bottom: 20px;
      }
      .image {
        grid-area: image;
        border-radius: 5px;
      }

      .main-info {
        grid-area: main-info;
        align-self: start;
        color: var(--black-color);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 51px;
      }

      .phone-info {
        grid-area: phone-info;
        align-self: start;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 30px;

        .phone {
          color: var(--green-color);
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p {
          color: var(--black-color);
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .phone-btn__block{
        grid-area: socials;
        align-content: start;
        align-self: start;
        grid-auto-rows: min-content;
        .phone-btn {
          border: 2px solid var(--gray-lines-color);
        }
      }
    }

  }


@media only screen and (max-width: $bp-big-pc) {
  .info-card {

    .content {
      width: 1320px;
    }
  }
}
@media only screen and (max-width: $bp-pc) {
  .info-card {

    .content {
      margin: 100px 0 100px 0;
      padding: 40px;
      max-width: 1140px;
      grid-template-areas:
          'title title'
          'image main-info'
          'phone-info phone-info'
          'socials socials';
      grid-gap: 0 30px;
      grid-template-rows: auto auto auto auto;

      .title {
        text-align: center;
        padding-bottom: 40px;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      .image {
        width: 210px;
        object-fit: cover;
      }
      .main-info {
        align-self: center;
        padding-bottom: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .phone-info {
        text-align: center;
        padding-top: 40px;
        padding-bottom: 20px;

        .phone {
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          p{
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
      .phone-btn__block {
        justify-content: center;
      }

    }
  }
}
@media only screen and (max-width: $bp-big-tablet) {
  .info-card {

    .content {
      max-width: 896px;
    }
  }
}
@media only screen and (max-width: $bp-tablet) {
  .info-card {

    .content {
      margin: 60px 0 110px 0;
      padding: 60px 20px 30px 20px;
      max-width: 670px;
      grid-template-areas:
          'title title'
          'image image'
          'main-info main-info'
          'phone-info phone-info'
          'socials socials';
      grid-gap: 0;
      grid-template-rows: auto auto auto auto auto;

      .image {
        width: 300px;
        height: 300px;
        object-fit: cover;
        align-self: stretch;
        justify-self: center;
        padding-bottom: 20px;
      }

      .title {
        padding-bottom: 20px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }

      .main-info {
        text-align: center;
        padding-bottom: 45px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .phone-info {
        padding-top: 0;
        padding-bottom: 10px;
        .phone {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p {
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}
</style>