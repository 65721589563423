<template>
  <div class="modal" @click="closeModal" v-if="isVisible">
    <div class="modal-content">
      <slot></slot>
      <button class="close-btn" @click="closeModal">Закрыть</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    formFull: String
  },
  methods: {
    closeModal() {
      this.$emit('update:formFull', "");
      this.$emit('update:isVisible', false);
    }
  },
  watch: {
    isVisible(val) {
      if (val) {
        document.body.classList.add('noscroll');
      } else {
        document.body.classList.remove('noscroll');
      }
    }
  },
  mounted() {
    if (this.isVisible) {
      document.body.classList.add('noscroll');
    }
  },
  beforeUnmount() {
    document.body.classList.remove('noscroll');
  }
}
</script>

<style lang="scss" >
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;

  .modal-content {
    background: var(--white-color);
    border: 2px solid var(--green-color);
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .modal-text {
      color: var(--green-color);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .close-btn {
      border-radius: 100px;
      padding: 10px 20px;
      border: 2px solid var(--green-color);
      color: var(--green-color);
      background: transparent;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;

      &:hover {
        border: 2px solid var(--white-color);
        background: var(--green-color);
        color: var(--white-color);
        transition: 0.2s ease-in-out;
      }
    }
  }
}
</style>
