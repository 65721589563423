<template>
  <section class="brands">
    <div class="content">
      <h2 class="title">
        Мы ремонтируем большинство марок холодильников
      </h2>
      <div class="brand__list">
        <div
            class="brand__item"
            v-for="brand in brands"
            :key="brand"
        >
          <span class="icon">
            <img :src="require(`../assets/images/${brand}_logo.webp`)" :alt="`${brand}_logo`">
          </span>
          <h3 class="brand__name">{{brand}}</h3>
        </div>
      </div>
      <div class="slider">
        <swiper class="swiper"
                slides-per-view="auto"
                :space-between="20"
        >
          <swiper-slide
              v-for="brand in brands"
              :key="brand"
              class="brand__item"
          >
            <div class="slide_wrapper">
              <img class="icon" :src="require(`../assets/images/${brand}_logo.webp`)" :alt="`${brand}_logo`">
              <h3 class="brand__name">{{brand}}</h3>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      brands: ['LG', 'Sharp', 'Liebherr', 'Indesit', 'Hotpoint',
        'Mitsubishi', 'Midea', 'Hitachi', 'Samsung', 'Siemens',
        'Haier', 'Bosch', 'Hisense', 'Vestel', 'Beko',
        'Pozis', 'Atlant', 'Birusa'],
    }
  },
  setup() {
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base/adaptive.scss";

.brands {
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .content{
    width: 1440px;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 0 40px;
    max-width: 100vw;

    .slider {
      display: none;
      max-width: 100%;

      .swiper {
        overflow: visible;
        max-width: 100%;

        .swiper-slide {
          border-radius: 10px;
          box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.10);
          display: flex;
          width: auto;

          .slide_wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 30px 20px;

            width: 180px;
            height: 171px;

            .icon{
              width: 140px;
              height: 70px;
              object-fit: contain;
            }

            .brand__name{
              text-align: center;
            }
          }
        }
        .swiper-slide:last-child {
          margin-right: 0!important;
        }
      }

    }

    .title{
      text-align: center;
    }

    .brand__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;

      .brand__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.10);
        height: 181px;
        justify-content: space-between;

        .icon{
          width: 150px;
          height: 70px;
          display: flex;
          justify-content: center;
          img {
            object-fit: none;
          }
        }
        .brand__name{
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
        }
      }
    }
  }
}
@media only screen and (max-width: $bp-big-pc) {
  .brands {

    .content {
      width: 1320px;
      .brand__list {
        gap: 20px;
        .brand__item {
          padding: 30px 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: $bp-pc) {
  .brands {
    overflow-x: hidden;
    .content {
      margin-bottom: 100px;

      .brand__list {
        display: none;
      }
      .slider {
        display: inline-block;

        .swiper {

        }
      }
    }
  }
}
@media only screen and (max-width: $bp-tablet) {
  .brands {

    .content {

      margin-bottom: 80px;
      gap: 30px;

      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .slider {

        .swiper {
          .swiper-slide {
            .slide_wrapper {
              width: 120px;
              height: 118px;
              align-items: center;
              justify-content: center;
              padding: 10px 15px;
              gap: 10px;

              .icon {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>