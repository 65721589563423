import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import YmapPlugin from 'vue-yandex-maps'
import './assets/styles/index.scss'
import {createHead} from "@vueuse/head";

const settings = {
    apiKey: '04dd3088-fd9e-4a2c-a0d8-c21a9ac17b9b',
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1',
}

const head = createHead()
const app = createApp(App)

app
    .use(router)
    .use(head)
    .use(YmapPlugin, settings)
    .mount('#app')
