function prefixNumber(num) {
    if (num === "8") {
        return "+7 ("
    } else return  "+7 ("
}

function phoneInput(tn) {
    const numberLength = 11
    const value = tn.target.value.replace(/\D+/g, "");
    let result = ""
    for (let i = 0; i < value.length && i < numberLength; i++) {
        switch (i) {
            case 0:
                result += prefixNumber(value[i]);
                continue;
            case 4:
                result += ") ";
                break;
            case 7:
                result += "-";
                break;
            case 9:
                result += "-";
                break;
            default:
                break;
        }
        result += value[i];
    }
    return result
}

export {phoneInput}