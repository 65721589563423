<template>
    <router-link
        :to="{ path: '/', hash: '#applicationForm' }"
        class="application-btn"
    >
      Оставить заявку
    </router-link>
</template>

<script>
export default {
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.application-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  height: 53px;
  padding: 15px 30px;
  background: var(--green-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--white-color);
  border-radius: 100px;
  border: 1px solid var(--white-color);
}
</style>